import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ModifiedModalFooter } from '../../elements'
import { Alert, Button, Form, Input, InputNumber, Modal, Space, Switch } from 'antd'
import { Can, CustomTable } from '../../components'

export const CountryDetailsModal = ({
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  selectedCountry,
  setModalLoading,
}) => {
  const [formError, setFormError] = useState(null)
  const [form] = Form.useForm()
  const isZeroDecimal = selectedCountry.locales[0] === 'ja-JP'

  const FullWidthNumberInput = (props) => (
    <Form.Item {...props} style={{ marginBottom: 0 }}>
      <InputNumber min={0} precision={isZeroDecimal ? 0 : 2} style={{ width: '100%' }} type="number" />
    </Form.Item>
  )

  FullWidthNumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    rules: PropTypes.array,
  }

  const handleOk = () => {
    setFormError(null)
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          let fieldValue = form.getFieldsValue([
            'fee_line',
            'fee_paypal',
            'fee_pring',
            'fee_wise',
            'min_free_withdrawal_line',
            'min_free_withdrawal_paypal',
            'min_free_withdrawal_pring',
            'min_free_withdrawal_wise',
            'min_withdrawal_amount_line',
            'min_withdrawal_amount_paypal',
            'min_withdrawal_amount_pring',
            'min_withdrawal_amount_wise',
            'privacy_policy_url',
            'transaction_email',
          ])

          fieldValue = {
            ...fieldValue,
            fee_line: fieldValue.fee_line || 0,
            fee_paypal: fieldValue.fee_paypal || 0,
            fee_pring: fieldValue.fee_pring || 0,
            fee_wise: fieldValue.fee_wise || 0,
            min_free_withdrawal_line: fieldValue.min_free_withdrawal_line || 0,
            min_free_withdrawal_paypal: fieldValue.min_free_withdrawal_paypal || 0,
            min_free_withdrawal_pring: fieldValue.min_free_withdrawal_pring || 0,
            min_free_withdrawal_wise: fieldValue.min_free_withdrawal_wise || 0,
            min_withdrawal_amount_line: fieldValue.min_withdrawal_amount_line || 0,
            min_withdrawal_amount_paypal: fieldValue.min_withdrawal_amount_paypal || 0,
            min_withdrawal_amount_pring: fieldValue.min_withdrawal_amount_pring || 0,
            min_withdrawal_amount_wise: fieldValue.min_withdrawal_amount_wise || 0,
          }

          onSubmit({ ...fieldValue, id: selectedCountry.id })
        })
        .catch((error) => {
          setFormError('”Minimum withdrawal amount in local currency” should be higher than “Fee paid by the customer below the minimum amount for a free withdrawal request”')
        })
    } else {
      hideModal()
    }
  }

  const initialValues = {
    ...selectedCountry,
    language_code: selectedCountry.locales[0],
    name: selectedCountry.name,
    privacy_policy_url: selectedCountry.privacy_policy_url,
    transaction_email: selectedCountry.transaction_email,
  }

  const paymentMethodTypes = {
    line: {
      id: 'line',
      name: 'Line Pay',
    },
    paypal: {
      id:  'paypal',
      name: 'Paypal',
    },
    pring: {
      id:  'pring',
      name: 'Pring',
    },
    wise: {
      id: 'wise',
      name: 'Wise',
    },
  }

  const countryPaymentMethods = {
    ca: ['wise', 'paypal'],
    fr: ['wise', 'paypal'],
    jp: ['line', 'pring', 'wise'],
    uk: ['wise', 'paypal'],
    us: ['wise', 'paypal'],
  }

  const paymentsSource = countryPaymentMethods[selectedCountry.code].map((paymentMethodName) => paymentMethodTypes[paymentMethodName])

  const tableColumns = [
    {
      className: 'transferType',
      dataIndex: 'name',
      key: 'type',
      title: '',
    },
    {
      className: 'minWithdrawalAmountSelenium',
      key: 'minWithdrawal',
      render: (_, record) => (
        <FullWidthNumberInput
          name={`min_withdrawal_amount_${record.id}`}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const floatFeeNumber = parseFloat(getFieldValue(`fee_${record.id}`))

                return ((parseFloat(value) > floatFeeNumber) || (floatFeeNumber === 0))
                  ? Promise.resolve()
                  : Promise.reject()
              },
            }),
          ]}
        />
      ),
      title: 'Minimum withdrawal amount in local currency',
    },
    {
      className: 'transactionTypeSelenium',
      key: 'minFeeWithdrawal',
      render: (_, record) => <FullWidthNumberInput name={`min_free_withdrawal_${record.id}`} />,
      title: 'Minimum amount for a free withdrawal request',
    },
    {
      className: 'transactionTypeSelenium',
      key: 'fee',
      render: (_, record) => <FullWidthNumberInput name={`fee_${record.id}`} />,
      title: 'Fee paid by the customer below the minimum amount for a free withdrawal request',
    },
  ]

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelCountryModalBtnSelenium' }}
      confirmLoading={isModalLoading}
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button key="back" name="cancelCountryModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key="update"
              requiredPermission="country.put.country_id"
              yes={() => (
                <Button data-testid="updateBtn" key="submit" loading={isModalLoading} name="updateDetailsCountrySelenium" onClick={handleOk} type="primary">
                  Update
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      okText="Update"
      onCancel={hideModal}
      onOk={handleOk}
      open
      title="Update country"
      width={615}
    >
      <Form data-test="country-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item label="Name" name="name">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Code" name="code">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Language Code" name="language_code">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Currency Name" name="currency_name">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Currency Symbol" name="currency_symbol">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Transactional emails" name="transaction_email" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item
          label="Link to terms and conditions"
          name="privacy_policy_url"
        >
          <Input />
        </Form.Item>

        <CustomTable columns={tableColumns} dataSource={paymentsSource} />
      </Form>

      {(errorMessage || formError) && (
        <Alert
          description={errorMessage || formError}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

CountryDetailsModal.propTypes = {
  errorMessage: PropTypes.node,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedCountry: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
}

export default CountryDetailsModal
