import React from 'react'
import PropTypes from 'prop-types'
import { ModifiedModalFooter } from '../../elements'
import { Alert, Button, Form, Input, InputNumber, Modal, Select, Space } from 'antd'
import { Can, ModalDeleteButton } from '../../components'

const { TextArea } = Input
const { Option } = Select

export const BinDetailsModal = ({
  binTypes,
  errorMessage,
  hideModal,
  isModalLoading,
  onDelete,
  onSubmit,
  retailers,
  selectedBin,
  setModalLoading,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedBin

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = form.getFieldsValue([
            'bin_type_id',
            'details',
            'latitude',
            'longitude',
            'name',
            'retailer_id',
          ])

          const actionType = isUpdate ? 'UPDATE' : 'CREATE'

          onSubmit(actionType, { ...fieldsValue, ...(isUpdate && { id: selectedBin?.id })})
        })
    } else {
      hideModal()
    }
  }

  const initialValues = {
    bin_type_id: selectedBin?.bin_type_id,
    details: selectedBin?.details ?? '',
    latitude: selectedBin?.latitude,
    longitude: selectedBin?.longitude,
    name: selectedBin?.name,
    retailer_id: selectedBin?.retailer?.id,
  }

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <ModalDeleteButton
            confirmationText="Are you sure you want to delete this bin?"
            isUpdate={isUpdate}
            key="delete"
            name="deleteBinModalBtnSelenium"
            onDelete={() => onDelete(selectedBin)}
            requiredPermission="bin.delete.bin_id"
          />
          <Space>
            <Button key="back" name="cancelBinModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>
            <Can
              key={isUpdate ? 'update' : 'add'}
              requiredPermission={isUpdate ? 'bin.put.bin_id' : 'bin.post.create'}
              yes={() => (
                <Button data-testid={isUpdate ? 'updateBtn' : 'addBtn'} key="submit" loading={isModalLoading} name={isUpdate ? 'updateDetailsBinSelenium' : 'addBinSelenium'} onClick={handleSubmit} type="primary">
                  {isUpdate ? 'Update' : 'Add'}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isUpdate ? 'Update bin' : 'Create bin'}
    >
      <Form data-test="bin-details-form" form={form} initialValues={initialValues} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ message: 'Please provide a name!', required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item data-testid="latInput" label="Latitude" name="latitude" rules={[{ message: 'Please provide a valid latitude!', required: true, type: 'number' }]}>
          <InputNumber step={0.000001} style={{ width: '100%' }} type="number" />
        </Form.Item>

        <Form.Item data-testid="lonInput" label="Lon" name="longitude" rules={[{ message: 'Please provide a valid longitude!', required: true, type: 'number' }]}>
          <InputNumber step={0.000001} style={{ width: '100%' }} type="number" />
        </Form.Item>

        <Form.Item label="Type" name="bin_type_id" rules={[{ message: 'Please select a bin type!', required: true }]}>
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {binTypes.map(({ text, value }) =>
              <Option key={value} name="binKindOption" value={value}>{text}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Retailers" name="retailer_id">
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {retailers.map(({ name, id }) =>
              <Option key={id} name="retailerOption" value={id}>{name}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Details" name="details">
          <TextArea />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

BinDetailsModal.propTypes = {
  binTypes: PropTypes.array,
  errorMessage: PropTypes.node,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  retailers: PropTypes.array,
  selectedBin: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
}

export default BinDetailsModal
