import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compact, isEmpty } from 'lodash'
import { Can } from '../../components'
import { ModifiedModalFooter } from '../../elements'
import { handleBackendError } from '../../utils'
import { Alert, Button, Card, Col, Divider, Form, Input, Modal, notification, Select, Space, Typography } from 'antd'
import { createStoreLink, getStoreList, updateStoreLink } from './api'

const { Paragraph, Title } = Typography
const { TextArea } = Input
const { Option } = Select

export const StoreDetailsModal = ({
  countries,
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  selectedStore,
  setModalLoading,
  tdsStores,
}) => {
  const [form] = Form.useForm()

  const isNameEmpty = isEmpty(Form.useWatch('name', form))
  const selectedCountryId = Form.useWatch('country_id', form)
  const selectedTdsStoreId = Form.useWatch('tds_store_id', form)

  const [usedTdsStoreIds, setUsedTdsStoreIds] = useState([])
  const [link, setLink] = useState('')

  const isCountryEmpty = isEmpty(selectedCountryId)
  const isTdsStoreEmpty = !selectedTdsStoreId
  const isUpdate = !!selectedStore
  const hasWiseId = isUpdate && selectedStore.wise_id

  useEffect(() => {
    getStoreList()
      .then(({ data }) => setUsedTdsStoreIds(() => compact(data.data.map(({ tds_store_id }) => tds_store_id ))))
      .catch((error) => console.warn(error, 'Fetching the initial store list failed!'))
  }, [])

  const handleSubmit = () => {
    const isFormUpdated = form.isFieldsTouched()

    if (isFormUpdated) {
      form
        .validateFields()
        .then(() => {
          setModalLoading(true)

          const fieldsValue = form.getFieldsValue([
            'active',
            'address_line',
            'city',
            'comment',
            'contact_name',
            'contact_phone',
            'country_id',
            'legal_entity',
            'name',
            'reporting_email',
            'state',
            'tds_store_id',
            'zip_code',
          ])

          const actionType = isUpdate ? 'UPDATE' : 'CREATE'

          onSubmit(actionType, { ...fieldsValue, ...(isUpdate && { id: selectedStore?.id })})
        })
    } else {
      hideModal()
    }
  }

  const addNewStoreLink = () => {
    const fieldsValue = form.getFieldsValue()

    createStoreLink(fieldsValue)
      .then(({ data }) => {
        setLink(`${window.location.origin}/admin/new-store/${data.data.edit_url_param}`)
      })
      .catch((error) => {
        const errorMessage = handleBackendError(error)
        notification.error({
          description: errorMessage,
          message: 'Error',
        })
      })
  }

  const generateStoreLink = () => {
    const basename = (window.location.host.split('.')[0] === 'admin') ? '' : '/admin'

    updateStoreLink(selectedStore.id)
      .then(({ data }) => {
        setLink(`${window.location.origin}${basename}/new-store/${data.data.edit_url_param}`)
      })
      .catch((error) => {
        const errorMessage = handleBackendError(error)
        notification.error({
          description: errorMessage,
          message: 'Error',
        })
      })
  }

  const initialValues = {
    active: selectedStore ? selectedStore.active : true,
    address_line: selectedStore?.address_line,
    balance: selectedStore ? new Intl.NumberFormat(selectedStore.country_locales[0], { currency: selectedStore.currency, style: 'currency' }).format(selectedStore?.balance || 0) : 0,
    city: selectedStore?.city,
    comment: selectedStore?.comment,
    contact_name: selectedStore?.contact_name,
    contact_phone: selectedStore?.contact_phone,
    country_id: selectedStore?.country_id,
    id: selectedStore?.id,
    legal_entity: selectedStore?.legal_entity,
    name: selectedStore?.name,
    reporting_email: selectedStore?.reporting_email,
    state: selectedStore?.state,
    tds_store_id: selectedStore?.tds_store_id || null,
    zip_code: selectedStore?.zip_code,
  }

  const isViewMode = !initialValues.active
  const selectedCountryCode = countries.filter(country => country.value === selectedCountryId)[0]?.code || null
  const filteredStores = tdsStores.filter(store => (store.country === selectedCountryCode) && !usedTdsStoreIds.includes(store.id))

  return (
    <Modal
      footer={[
        <ModifiedModalFooter key="padding">
          <Space>
            <Button key="back" name="cancelStoreModalBtnSelenium" onClick={hideModal}>
              Cancel
            </Button>

            <Can
              key={isUpdate ? 'update' : 'add'}
              requiredPermission={isUpdate ? 'store.put.store_id' : 'store.post.create'}
              yes={() => (
                <Button
                  data-testid={isUpdate ? 'updateBtn' : 'addBtn'}
                  key="submit"
                  loading={isModalLoading}
                  name={isUpdate ? 'updateDetailsStoreSelenium' : 'addStoreSelenium'}
                  onClick={handleSubmit}
                  type="primary"
                >
                  {isUpdate ? 'Update' : 'Add'}
                </Button>
              )}
            />
          </Space>
        </ModifiedModalFooter>,
      ]}
      onCancel={hideModal}
      onOk={handleSubmit}
      open
      title={isUpdate ? 'Update store' : 'Create store'}
    >
      <Form data-test="store-details-form" form={form} initialValues={initialValues} layout="vertical">
        {isUpdate &&
          <Form.Item label="ID" name="id">
            <Input disabled />
          </Form.Item>
        }

        <Form.Item label="Name" name="name" rules={[{ message: 'Please provide a name!', required: true }]}>
          <Input disabled={isViewMode} />
        </Form.Item>

        <Divider />

        <Title level={5}>Address</Title>

        <Form.Item label="Country" name="country_id" rules={[{ message: 'Please select a country!', required: true }]}>
          <Select
            data-testid="countrySelect"
            disabled={isViewMode || isUpdate}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={() => {form.setFieldsValue({ tds_store_id: null })}}
            showSearch
          >
            {countries.map(({ text, value }) =>
              <Option key={value} name="storeCountryOption" value={value}>{text}</Option>,
            )}
          </Select>
        </Form.Item>

        <Form.Item label="TDS Store name" name="tds_store_id" rules={[{ message: 'Please select a TDS Store!', required: true }]}>
          <Select
            data-testid="tdsStoreNameSelect"
            disabled={isCountryEmpty || selectedStore?.tds_store_id}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {filteredStores.map(({ id, name }) =>
              <Option key={id} name="tdsStoreNameOption" value={id}>{name}</Option>,
            )}
          </Select>
        </Form.Item>

        {!hasWiseId && (
          <Col
            style={{
              textAlign: 'right',
            }}
          >
            <Can
              key="storeLink"
              requiredPermission="store.post.create"
              yes={() => (
                <Button
                  data-testid={isUpdate ? 'generateNewLink' : 'addNewLink'}
                  disabled={isNameEmpty || isCountryEmpty || isTdsStoreEmpty}
                  key="submit"
                  name="createNewStoreWithLinkSelenium"
                  onClick={() => isUpdate ? generateStoreLink() : addNewStoreLink()}
                  type="link"
                >
                  {isUpdate ? 'Generate new link' : 'Add new store link'}
                </Button>
              )}
            />
          </Col>
        )}

        <Col>
          {!isEmpty(link) && (
            <Card
              bordered={false}
              data-testid="linkCard"
              style={{ backgroundColor: '#f0f2f5', width: '100%' }}
            >
              <Paragraph copyable>{link}</Paragraph>
            </Card>
          )}
        </Col>

        <Form.Item
          label="State"
          name="state"
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="Zip code"
          name="zip_code"
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Form.Item
          label="Address Line"
          name="address_line"
        >
          <Input disabled={isViewMode} />
        </Form.Item>

        <Divider />

        <Form.Item label="Status" name="active" rules={[{ message: 'Please select a status!', required: true }]}>
          <Select>
            <Option name="storeStatusOption" value={true}>Active</Option>,
            <Option name="storeStatusOption" value={false}>Inactive</Option>,
          </Select>
        </Form.Item>

        <Form.Item label="Legal Entity" name="legal_entity">
          <Input />
        </Form.Item>

        <Form.Item
          label="Reporting Email"
          name="reporting_email"
          rules={[
            {
              message: 'The input is not valid email!',
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {isUpdate &&
          <Form.Item label="Balance" name="balance">
            <Input disabled />
          </Form.Item>
        }

        <Form.Item label="Comment" name="comment">
          <TextArea disabled={isViewMode} />
        </Form.Item>

        <Title level={5}>Contact informations</Title>

        <Form.Item
          label="Contact name"
          name="contact_name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Contact phone"
          name="contact_phone"
          rules={[{
            message: 'Please add a valid phone number!',
            pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im),
          }]}
        >
          <Input />
        </Form.Item>
      </Form>

      {errorMessage && (
        <Alert
          description={errorMessage}
          message="Error"
          showIcon
          style={{ marginTop: 20 }}
          type="error"
        />
      )}
    </Modal>
  )
}

StoreDetailsModal.propTypes = {
  countries: PropTypes.array,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedStore: PropTypes.object,
  setModalLoading: PropTypes.func.isRequired,
  tdsStores: PropTypes.array,
}

export default StoreDetailsModal
